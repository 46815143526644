import React from 'react'
import {Link} from "react-router-dom";


import Sidebar from './Sidebar'

import {Helmet} from "react-helmet";

function apikey() {
  return (
    <div className='sb-nav-fixed'>

<Helmet>
    <meta charSet="utf-8" />
    <title>Api Key My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 

<div id="layoutSidenav" className='overlay pt-120'>

	<Sidebar />





  <div id="layoutSidenav_content">
        <main>
            <div className="container-fluid px-4">
               
               

                <h1>Api Key </h1>

                <div class="card bg-primary text-white mb-4"> </div>
       
                <div class="row">
    <div class="col-xl-12 col-md-12">
        <div class="card shadow-lg border-0 rounded-lg mt-5">
            <div class="card-body">
                <form method="post" accept-charset="utf-8" action="/client/apiKey">
                   
                   
                    <div class="form-floating mb-3">
                        <input class="form-control" type="text" value="PSw0Ow6OXqPGf6j3bSUz7k2Z" readonly="" />
                        <label for="inputEmail">Key</label>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="mb-3" style={{float: "left", width: "48%"}}>
                                <input type="text" class="form-control text-center" name="" value="10 + 12" required="required" disabled="" />
                            </div>
                            <div class="mb-3" style={{float: "right", width: "48%"}}>
                                <input type="number" name="answer" required="required" placeholder="Answer ?" class="form-control text-center" />
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <input class="btn btn-primary" value="Generated" type="submit" style={{width: "100%"}} />
                    </div>

                </form>
            </div>
            <div class="card-footer text-center py-3">
                <div class="small"><Link to="../tronlinkpro-trc20-api-service-documentation">How to Use!</Link></div>
            </div>
        </div>
    </div>
</div>









            </div>
        </main>
        


    </div>
	

      
      

</div>

      
    </div>
  )
}

export default apikey
