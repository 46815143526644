import React from 'react'
import {Helmet} from "react-helmet";

function signup() {
  return (
    <div>

<Helmet>
    <meta charSet="utf-8" />
    <title>signup My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 


<section className="login-reg">
  <div className="overlay pt-120">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-xl-6 order-xl-0 order-1">
          <div className="sec-img d-rtl"> <img src="/assets/images/login-reg-bg.png" className="max-un" alt="image" /> </div>
        </div>
        <div className="col-xl-5">
          <div className="section-text text-center"> <a className="navbar-brand" href="https://pupay.net/"> <img src="/assets/images/pupay-logo.png" className="logo" alt="logo" /> </a>
            <h5 className="sub-title"> </h5>
            <p className="dont-acc">Already have an account? <a href="/users/login">Login</a></p>
                      </div>
          <form method="post" accept-charset="utf-8" action="/users/signup">         <div className="row">
            <div className="col-12">
              <div className="single-input">
                <label for="logemail">Your Email</label>
                <input type="email" name="email" placeholder="Enter Your Email" value="" required="" />
              </div>
              <div className="single-input">
                <label for="logpassword">Your Password</label>
                <input type="password" name="password" placeholder="Enter Your Password" required="" />
              </div>
              <div className="single-input">
                <label for="logpassword">Name </label>
                <input type="text" name="name" placeholder="Enter Your Name" value="" required="" />
              </div>
              <div className="single-input">
                <label>Country</label>
                <select name="country" id="country" required="required"><option value="">Select</option><option value="Afghanistan">Afghanistan</option></select>
              </div>
              <div className="single-input">
                <label for="logpassword">WhatsApp Contact Number </label>
                <input type="text" name="phone" placeholder="+X XX XXXXXXXXX" value="" required="" />
              </div>
              <button className="cmn-btn w-100">Submit</button>
            </div>
          </div>
          </form>        </div>
      </div>
    </div>
  </div>
</section>


      
    </div>
  )
}

export default signup
