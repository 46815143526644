import React from 'react'

function footer() {
  return (
    <div>






    <footer className="footer-section">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                   
                </div>
            </div>
            <div className="footer-area pt-120">
                <div className="footer-top">
                    <div className="row align-items-center">
                        <div className="col-sm-6 d-flex justify-content-center justify-content-sm-start">
                            <div className="menu-item">
                                <ul className="footer-link d-flex align-items-center">
                                    <li><a href="">About Us</a></li>
                                    <li><a href="">Support</a></li>
                                    <li><a href="">Fees</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="social-link d-flex justify-content-sm-end justify-content-center align-items-center">
                                <a href="javascript:void(0)"><img src={process.env.PUBLIC_URL + "/assets/images/icon/facebook.png"} alt="icon" /></a>
                                <a href="javascript:void(0)"><img src={process.env.PUBLIC_URL + "/assets/images/icon/linkedin.png"} alt="icon" /></a>
                                <a href="javascript:void(0)"><img src={process.env.PUBLIC_URL + "/assets/images/icon/instagram.png"} alt="icon" /></a>
                                <a href="javascript:void(0)"><img src={process.env.PUBLIC_URL + "/assets/images/icon/twitter.png"} alt="icon" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-start order-sm-0 order-1">
                            <div className="copyright text-center text-sm-start">
                                <p> Copyright © 2022 <a href="">PUPay.</a> All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-4">
                            <div className="menu-item">
                                <ul className="footer-link d-flex justify-content-sm-end justify-content-center align-items-center">
                                    <li><a href="">Terms</a></li>
                                    <li><a href="">Privacy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>




      
    </div>
  )
}

export default footer
