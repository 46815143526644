import React from 'react'


import Sidebar from './Sidebar'

import {Helmet} from "react-helmet";

function dashboard() {
  return (
    <div className='sb-nav-fixed'>

<Helmet>
    <meta charSet="utf-8" />
    <title>dashboard My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 

<div id="layoutSidenav" className='overlay pt-120'>

	<Sidebar />





  <div id="layoutSidenav_content">
        <main>
            <div className="container-fluid px-4">
               
               

                <h1>Dashboard</h1>

                <div class="card bg-primary text-white mb-4"> </div>
       

                <div class="row text-center">
    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background: "#e0ffd1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0 / 0</h5>
                <p>Callback Balance / TRX / Energy</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#e0ffd1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Today Deposit</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#e0ffd1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Yesterday Deposit</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#e0ffd1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                <p>Total Deposit</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#d1ffe4"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0 / 0</h5>
                <p>TRX Balance / TRX / Energy</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-2 col-xl-12">
        <div class="card custom-card" style={{background :"#d1ffe4"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Pending Callback</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-2 col-xl-12">
        <div class="card custom-card" style={{background :"#d1ffe4"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Today Callback</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-2 col-xl-12">
        <div class="card custom-card" style={{background :"#d1ffe4"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Yesterday Callback</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#d1ffe4"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                <p>Total Callback</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#fff1d1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0 / 0</h5>
                <p>Withdrawal Balance / TRX / Energy</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#fff1d1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Today Withdrawal</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#fff1d1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Yesterday Withdrawal</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#fff1d1"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                <p>Total Withdrawal</p>
            </div>
        </div>
    </div>

    <h1 class="mt-4">Balance</h1>
    <div class="card bg-primary text-white mb-4"> </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#d1dfff"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Today Energy Buy</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#d1dfff"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0 / 0.00</h5>
                <p>Yesterday Energy Buy</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#d1dfff"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                <p>Today TRX Deposit</p>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-12">
        <div class="card custom-card" style={{background :"#d1dfff"}}>
            <div class="card-body">
                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                <p>Yesterday TRX Deposit</p>
            </div>
        </div>
    </div>
</div>











            </div>
        </main>
        


    </div>
	

      
      

</div>

      
    </div>
  )
}

export default dashboard
