import React from 'react'
import {Helmet} from "react-helmet";

function home() {
  return (
    <div>

<Helmet>
    <meta charSet="utf-8" />
    <title>My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 


<section className="banner-section index">
        <div className="overlay">
            <div className="shape-area">
                <img src="assets/images/banner-box.png" className="obj-1" alt="pupay" />
                <img src="assets/images/banner-human.png" className="obj-2" alt="pupay" />
                <img src="assets/images/banner-rocket.png" className="obj-3" alt="pupay" />
                <img src="assets/images/banner-clock.png" className="obj-4" alt="pupay" />
            </div>
            <div className="banner-content">
                <div className="container wow fadeInUp">
                    <div className="content-shape">
                        <img src="assets/images/banner-wallet.png" className="obj-1" alt="pupay" />
                    </div>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="main-content">
                                <div className="top-area section-text">
                                    <h5 className="sub-title">Trusted by over 3M customers</h5>
                                    <h1 className="title">Pay Anyone, Anywhere</h1>
                                    <p className="xlr">Quickly and easily send, receive and request money online with PUPay. Get a customised solution to fit your business needs.</p>
                                </div>
                                <div className="bottom-area d-xxl-flex">
                                    <a href="https://api.pupay.net/users/signup" className="cmn-btn">Open a Free Account</a>
                                    <a className="cmn-btn active" href="tronlinkpro-trc20-api-service-documentation">See How it Works</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-md-6">
                        
                        
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className="counter-section">
            <div className="container wow fadeInUp">
                <div className="row cus-mar">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                        
<div className="overa">
    <table id="datatablesSimple0" list="50">
        <thead>
            <tr>
                <th>SL</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Created</th>
                <th>hash</th>
            </tr>
        </thead>
        <tbody>
            <tr className="text-danger">
                <td>1</td>
                <td>Energy 1000000 (1D)</td>
                <td>148.05</td>
                <td>2024-03-18 08:14AM</td>
                <td>3072eae276372f5ddbd2c013e8b15f3a</td>
            </tr>
            <tr className="text-danger">
                <td>2</td>
                <td>Energy 1000000 (1D)</td>
                <td>148.05</td>
                <td>2024-03-18 06:27AM</td>
                <td>6916cab357e29deb79d751cfc243d293</td>
            </tr>
            <tr className="text-success">
                <td>3</td>
                <td>Trx Deposit</td>
                <td>10.00</td>
                <td>2024-03-17 09:06PM</td>
                <td></td>
            </tr>
            <tr className="text-danger">
                <td>4</td>
                <td>Energy 32000 (1H)</td>
                <td>3.93</td>
                <td>2024-03-17 08:39PM</td>
                <td>42c43140e0294a4b41e8afc07e39d6ad</td>
            </tr>
            <tr className="text-success">
                <td>5</td>
                <td>Trx Deposit</td>
                <td>10.00</td>
                <td>2024-03-17 09:44AM</td>
                <td></td>
            </tr>
            <tr className="text-danger">
                <td>6</td>
                <td>Energy 1000000 (1D)</td>
                <td>148.05</td>
                <td>2024-03-17 12:40AM</td>
                <td>75ae439980e5e39443a3f98d9be17bc8</td>
            </tr>
            <tr className="text-danger">
                <td>7</td>
                <td>Energy 1000000 (1D)</td>
                <td>148.05</td>
                <td>2024-03-16 11:05AM</td>
                <td>935067870bd545d99b49e79a75ac1797</td>
            </tr>
            <tr className="text-danger">
                <td>8</td>
                <td>Energy 2000000 (1D)</td>
                <td>296.10</td>
                <td>2024-03-16 04:06AM</td>
                <td>84cb2c96eb567645fb3d391751659f9f</td>
            </tr>
            <tr className="text-success">
                <td>9</td>
                <td>Trx Deposit</td>
                <td>7.00</td>
                <td>2024-03-18 03:59PM</td>
                <td></td>
            </tr>
            <tr className="text-danger">
                <td>10</td>
                <td>Energy 1000000 (1D)</td>
                <td>148.05</td>
                <td>2024-03-15 09:27AM</td>
                <td>6378f316c6f313d029343d61d09bf940</td>
            </tr>
            <tr className="text-success">
                <td>11</td>
                <td>Trx Deposit</td>
                <td>1,127.58</td>
                <td>2024-03-15 09:25AM</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
                        
                        
                    </div>
                     
                </div>
            </div>
        </div>
    </section>
	

      
    </div>
  )
}

export default home
