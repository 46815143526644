import React from 'react'
import {Link} from "react-router-dom";


function sidebar() {
  return (
    <div>


<div id="layoutSidenav_nav">
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <div className="sb-sidenav-menu-heading0 text-center pt-4">Balance : 0.00 TRX <br /> Limit : 100  </div>
                    <div class="sb-sidenav-menu-heading">API</div>
                    <Link class="nav-link" to="../panel/dashboard"> <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div> Dashboard </Link>
		  
		  
                    <a class="nav-link" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts2" aria-expanded="false" aria-controls="collapseLayouts2" style={{background: "#313131"}}>
                    <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                    Tron Link Pro
                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </a>
                    <div class="collapse show" id="collapseLayouts2" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav class="sb-sidenav-menu-nested nav"> 
                        <Link class="nav-link" to="../panel/tron-deposit-list"> Address List </Link> 
                        
                        
                        
                        </nav>
                    </div>
          

                    <Link class="nav-link" to="../panel/apikey"><div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>  API Key </Link>

                    <Link class="nav-link" to="../panel/profile"><div class="sb-nav-link-icon"><i class="fas fa-table"></i></div> Profile </Link>

                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                puPay Admin
            </div>
        </nav>
    </div>

      
    </div>
  )
}

export default sidebar

