import React,{Component} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css';

import Header from './homes/Header'
import Footer from './homes/Footer'
import Home from './homes/Home' 
import About from './homes/About'
import Tronlinkpro from './homes/Tronlinkpro'
import Tronenergy from './homes/Tronenergy'
import Login from './login/Login'
import Signup from './login/Signup'

import Dashboard from './panel/Dashboard' 
import Profile from './panel/Profile' 
import Profileedit from './panel/Profile-edit' 
import Apikey from './panel/Apikey' 
import Trondepositlist from './panel/Tron-deposit-list' 



class App extends Component {
  render() {
    return (
		<BrowserRouter>

		<Header />
    
		<Routes>
			
			<Route path="/" element={<Home />}/>
			<Route path="/about" element={<About />}/> 
			<Route path="/tronlinkpro-trc20-api-service-documentation" element={<Tronlinkpro />}/> 
			<Route path="/tron-energy-exchange" element={<Tronenergy />}/> 
			<Route path="/users/login" element={<Login />}/> 
			<Route path="/users/signup" element={<Signup />}/> 



			<Route path="/panel/dashboard" element={<Dashboard />}/> 
			<Route path="/panel/profile" element={<Profile />}/> 
			<Route path="/panel/profile-edit" element={<Profileedit />}/> 
			<Route path="/panel/apikey" element={<Apikey />}/> 
			<Route path="/panel/tron-deposit-list" element={<Trondepositlist />}/> 

			

		</Routes>
    
		<Footer />
		</BrowserRouter>
    ); 
  }
}

export default App
