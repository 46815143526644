import React from 'react'
import {Link} from "react-router-dom";


import Sidebar from './Sidebar'

import {Helmet} from "react-helmet";

function profile() {
  return (
    <div className='sb-nav-fixed'>

<Helmet>
    <meta charSet="utf-8" />
    <title>Profile My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 

<div id="layoutSidenav" className='overlay pt-120'>

	<Sidebar />





  <div id="layoutSidenav_content">
        <main>
            <div className="container-fluid px-4">
               
               

                <h1>Profile  <Link to="../panel/profile-edit" class="btn waves-effect waves-light btn-rounded btn-primary">Edit</Link> </h1>

                <div class="card bg-primary text-white mb-4"> </div>
       
                <div class="row">
                    <div class="col-xl-12 col-md-12">
                        <div class="card shadow-lg border-0 rounded-lg mt-5">
                            <div class="card-body">
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputEmail" type="text" value="mdshohaelkhan@gmail.com" readonly="" />
                                    <label for="inputEmail">Email </label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="Md shohel khan" readonly="" />
                                    <label for="inputName">Name </label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="Bangladesh" readonly="" />
                                    <label for="inputName">Country </label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="+8801618882288" readonly="" />
                                    <label for="inputName">Phone </label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="Active" readonly="" />
                                    <label for="inputName">Status </label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="" readonly="" />
                                    <label for="inputName">Deposit CallBack Address </label>
                                </div>

                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="012345678901234567890" readonly="" />
                                    <label for="inputName">TrX Transfer Address </label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="012345678901234567890" readonly="" />
                                    <label for="inputName">Withdrawal Address </label>
                                </div>

                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="1012345678901234567890" readonly="" />
                                    <label for="inputName">BNB Deposit CallBack Address </label>
                                </div>

                                <div class="form-floating mb-3">
                                    <input class="form-control" id="inputName" type="text" value="0123456789012345678901" readonly="" />
                                    <label for="inputName">BNB Withdrawal Address </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
        </main>
        


    </div>
	

      
      

</div>

      
    </div>
  )
}

export default profile
