import React from 'react'
import {Helmet} from "react-helmet";

function login() {
  return (
    <div>

<Helmet>
    <meta charSet="utf-8" />
    <title>login My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 



<section className="login-reg">
  <div className="overlay pt-120">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-xl-6 order-xl-0 order-1">
          <div className="sec-img d-rtl"> <img src="/assets/images/login-reg-bg.png" className="max-un" alt="image" /> </div>
        </div>
        <div className="col-xl-5">
          <div className="section-text text-center"> <a className="navbar-brand" href="https://pupay.net/"> <img src="/assets/images/pupay-logo.png" className="logo" alt="logo" /> </a>
            <h5 className="sub-title"></h5>
            <h2 className="title">Log in to Continue</h2>
            <p className="dont-acc">Don't have an account? <a href="/users/signup">Signup</a></p>
                      </div>
          <form method="post" accept-charset="utf-8" action="">         
          <div className="row">
            <div className="col-12">
              <div className="single-input">
                <label for="logemail">Your Email</label>
                <input type="email" name="email" placeholder="Enter Your Email" />
              </div>
              <div className="single-input">
                <label for="logpassword">Your Password</label>
                <input type="password" name="password" placeholder="Enter Your Password" />
              </div>
              <button className="cmn-btn w-100">Login</button>
            </div>
          </div>
          </form>          <div className="forgot-pass mt-30 text-center"> <a href="/users/forget-password">Forgot Password</a> </div>
        </div>
      </div>
    </div>
  </div>
</section>

      
    </div>
  )
}

export default login
