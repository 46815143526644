import React from 'react'


import Sidebar from './Sidebar'

import {Helmet} from "react-helmet";

function trondepositlist() {
  return (
    <div className='sb-nav-fixed'>

<Helmet>
    <meta charSet="utf-8" />
    <title>Tron Deposit Link My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 

<div id="layoutSidenav" className='overlay pt-120'>

	<Sidebar />





  <div id="layoutSidenav_content">
        <main>
            <div className="container-fluid px-4">
               
               

                <h1>Tron Deposit List <a href="">Recall</a> </h1>

                <div class="card bg-primary text-white mb-4"> </div>
       

                <div class="row">
                    <div class="col-xxl-4 col-xl-12">
                    <div class="card custom-card" style={{background: "#e0ffd1"}}>
                    <div class="card-body">
                                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                                <p class="fw-semibold">Balance</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-4 col-xl-12">
                    <div class="card custom-card" style={{background: "#e0ffd1"}}>
                    <div class="card-body">
                                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                                <p class="fw-semibold">Total Callback</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-12">
                        <div class="card custom-card" style={{background: "#e0ffd1"}}>
                            <div class="card-body">
                                <h5 class="fw-semibold mb-3 lh-1">0.00</h5>
                                <p class="fw-semibold">Total Deposit</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-md-12">
                        <div class="card bg-primary text-white mb-4"></div>
                        <div class="card mb-4">
                            <div class="card-header">
                                <i class="fas fa-table me-1"></i> 
                                Address
                            </div>
                            <div class="overa">
                                <table id="datatablesSimple0" list="50">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Address</th>
                                            <th>Balance</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                            <th>CDate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td><a href="https://tronscan.org/#/address/TQDEEhMxE5Hczajm3HRtJbXyakA9BKTyTv/transfers" target="_blank">TQDEEhMxE5Hczajm3HRtJbXyakA9BKTyTv</a></td>
                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td><a href="/client/reload/1651">Reload</a></td>
                                            <td>9/23/24</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
        </main>
        


    </div>
	

      
      

</div>

      
    </div>
  )
}

export default trondepositlist
