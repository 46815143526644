import React from 'react'
import {Helmet} from "react-helmet";

function about() {
  return (
    <div>

<Helmet>
    <meta charSet="utf-8" />
    <title>about My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet> 





about  Page 



      
    </div>
  )
}

export default about
