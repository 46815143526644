import React from 'react'
import {Link} from "react-router-dom";


function header() {
  return (
    <div>


    <a href="javascript:void(0)" className="scrollToTop"><i className="fas fa-angle-double-up"></i></a>


    <header className="header-section">
        <div className="overlay">
            <div className="container">
                <div className="row d-flex header-area">
                    <nav className="navbar navbar-expand-lg navbar-light">
                    <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!" style={{display: "contents"}}>  <i class="fas fa-bars usermenuicon"></i></button>
                       
                        <a className="navbar-brand" href="https://pupay.net/">
                            <img src={process.env.PUBLIC_URL + "/assets/images/pupay-logo.png"} className="logo" alt="pupay" />
                        </a>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-content">
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbar-content">
                            <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                                 
                                <li className="nav-item dropdown main-navbar">
                                    <Link to="/tronlinkpro-trc20-api-service-documentation" className="nav-link" > Tron </Link> 
                                </li>
                                
                                <li className="nav-item dropdown main-navbar">
                                    <a className="nav-link" href="/tron-energy-exchange">Energy </a> 
                                </li>
                                 
                                 
                            </ul>
                            <div className="right-area header-action d-flex align-items-center max-un">
                                <Link to="./tronlinkpro-trc20-api-service-documentation" className="login">API</Link>
								<Link to="/users/login" className="cmn-btn"> Login  <i className="icon-d-right-arrow-2"></i> </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>


      
    </div>
  )
}

export default header
